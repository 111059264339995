import httpUtilWithMsg, {
  httpUtilWithNoMsg as httpUtil,
  httpUtilWithExtend,
  getClientJSFingerprint
} from "@/utils/HttpUtil";

/**
 * @typedef NodeAmountStatisticsRequest {{
 *   [conditions]: {Object}
 *   [statisticsBy]: string[],
 *   [pagination]: {
 *     [start]: number,
 *     [limit]: number,
 *     [orderBy]: string,
 *     [orderType]: string,
 *   },
 * }}
 */

export const API_ExpandNode = (viewId, nodeId, size = 100) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    nodeId,
    size,
    types: [9],
    match: 0,
  });
};

export const API_GrowNode = (viewId, nodeId, size = 100) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    nodeId,
    size,
    match: 0,
  });
};

export const API_ExactMatchesByNode = (viewId, nodeId, params, size = 100) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    ...params,
    nodeId,
    size,
    match: 1,
  });
};

export const API_MatchesBySubView = (viewId, subViewId, params) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    size: 100,
    subViewId: viewId === subViewId ? undefined : subViewId,
    match: viewId === subViewId ? 3 : 2,
    ...params,
  });
};

export const API_ExploreBySubView = (
  viewId,
  nodeId,
  relatedObjects,
  relatedTags,
  params,
  size = 100,
  withRelation = false,
  alg = 0,
  withTagSummary = false
) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    nodeId,
    relatedObjects,
    relatedTags,
    size,
    withRelation,
    match: withRelation ? 21 : 2,
    alg,
    withTagSummary,
    ...params,
  });
};

export const API_ExploreByNode = (
  viewId,
  subViewId,
  nodeId,
  relatedObjects,
  params,
  size = 100,
  withRelation = false,
  alg = 0,
  withTagSummary = false
) => {
  return httpUtil.post(`/view/graph/re/explore/${viewId}`, {
    subViewId: viewId === subViewId ? undefined : subViewId,
    nodeId,
    relatedObjects,
    size,
    withRelation,
    withTagSummary,
    match: withRelation ? 91 : 9,
    alg,
    ...params,
  });
};

export const API_RemoveEdge = (viewId, from, to) => {
  //return httpUtil.delete(`/view/relation/${viewId}/edge/${from}~${to}`);
  return httpUtil.delete(`/map_v2/relation/map/${viewId}/edge/${from}~${to}`);
};

export const API_RemoveNode = (viewId, nodeId) => {
  //return httpUtil.delete(`/view/relation/${viewId}/node/${nodeId}`);
  return httpUtil.delete(`/map_v2/relation/map/${viewId}/node/${nodeId}`);
};

export const API_GetRelationData = (
  viewId,
  type,
  withDetail,
  afterTime = undefined,
  userConfirmedOnly = true
) => {
  /*return httpUtil.get(`/view/relation/${viewId}/graph`, {
    with_detail: withDetail ? 1 : 0,
    after_time: afterTime || undefined,
    user_confirmed_only: userConfirmedOnly ? 1 : 0,
  });*/
  return httpUtil.get(`/map_v2/relation/map/${viewId}/graph`, {
    with_detail: withDetail ? 1 : 0,
    after_time: afterTime || undefined,
    user_confirmed_only: userConfirmedOnly ? 1 : 0,
  });
};

export const API_GetNodeInfo = (viewId, nodeType, nodeId) => {
  //return httpUtil.get(`/view/relation/${viewId}/node/${nodeId}`, {axios: { loading_msg: null, asAjax: true },node_type: nodeType,});
  return httpUtil.get(`/map_v2/relation/map/${viewId}/node/${nodeId}`, {axios: { loading_msg: null, asAjax: true },node_type: nodeType,});
};

export const API_UpdateNodeInfo = (viewId, nodeId, nodeInfo) => {
  //return httpUtil.put(`/view/relation/${viewId}/node/${nodeId}`, nodeInfo);
  return httpUtil.put(`/map_v2/relation/map/${viewId}/node/${nodeId}`, nodeInfo);
};

export const API_UpdateNodeInfoPartially = (viewId, nodeId, nodeInfo) => {
  return httpUtil.patch(`/view/relation/${viewId}/node/${nodeId}`, nodeInfo);
};

export const API_UpdateFilesRemark = (propertyId, params) => {
  return httpUtil.put(`/view/project/attach/${propertyId}`, params);
};

export const API_UploadProperty = (formData, progressCallback = undefined) => {
  return httpUtil.post(
    "/view/project/upload",
    formData,
    undefined,
    progressCallback
  );
};

export const API_RemoveFile = (id) => {
  return httpUtil.get(`/view/project/file/del`, { id });
};

export const API_RemoveFileItem = (id) => {
  return httpUtil.get(`/view/project/file/unbind/${id}`, { id });
};
/* 
export const API_AddNewView = (viewInfo) => {
  return httpUtil.post(`/view/view`, viewInfo);
};
*/
export const API_AddNewView = (viewInfo) => {
  //return httpUtil.post(`/map_v2/map/create`, viewInfo);
  return httpUtil.post(`/map_v2/map/create`, viewInfo);
};

// 获取指定图谱信息
export const API_GetViewById = (viewId) => {
  return httpUtil.get(`/view/view/info/${viewId}`);
};

export const API_UpdateView = (viewId, params) => {
  return httpUtil.post(`/view/view/config/set/${viewId}`, params);
};

export const API_DeleteView = (viewId, params) => {
  //return httpUtil.get(`/view/view/del/${viewId}`, { params });
  return httpUtil.get(`/map_v2/map/del/${viewId}`, { params });
};

export const API_GetFileList = (viewId) => {
  return httpUtil.get(`/view/project/file/list`, { viewId });
};

export const API_PublishViewToStore = (viewId, price) => {
  return httpUtil.post(`/view/trans/up/${viewId}`, { price });
};

export const API_RemoveViewFromStore = (viewId) => {
  return httpUtil.get(`/view/trans/down/${viewId}`);
};

// 上架图谱列表（开放复制）
export const API_GetPublishedViewList = ({ q, start, limit, channel}) => {
  return httpUtil.get(`/view/trans/listUp`, { q, start, limit, channel});
};

// 公开图谱列表
export const API_GetPublicViewList = ({ q, start, limit, teamwork_type, channel}) => {
  return httpUtil.get(`/map_v2/map/list/public`, {
    q,
    start,
    limit,
    teamwork_type,
    channel,
    ignore_owned_by_ai: 1,
  });
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=public&limit=60`, {q, start, limit, teamwork_type, ignore_owned_by_ai:1})
};

export const API_GetPublicViewListAll = ({ q, start, limit, teamwork_type, channel,ignore_owned_by_ai}) => {
  return httpUtil.get(`/map_v2/map/list/public`, {
    q,
    start,
    limit,
    teamwork_type,
    channel,
    ignore_owned_by_ai: ignore_owned_by_ai,
  });
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=public&limit=60`, {q, start, limit, teamwork_type, ignore_owned_by_ai:1})
};

// 获取首页图谱列表（未登录下所有可访问图谱）
export const API_GetGuestPublicViewList = ({
  q,
  start,
  limit,
  teamwork_type,
}) => {
  return httpUtil.get(`/view/public/view/list`, {
    q,
    start,
    limit,
    teamwork_type,
    ignore_owned_by_ai: 1,
  });
};

// 获取首页免费图谱列表（未登录下所有免费图谱）
export const API_GetGuestPublicViewListOfFree = (params) => {
  return httpUtil.get(`/view/public/view/list/free`, params);
};

// 可访问的图谱列表
export const API_GetAccessibleViewList = ({
  q,
  start,
  limit,
  teamwork_type,
  channel
}) => {
  return httpUtil.get(`/view/view/list/accessible`, {
    q,
    start,
    limit,
    teamwork_type,
    channel
  });
};

// 我的图谱列表，不包含协作
export const API_GetMyViewList = (userId, params) => {
  return httpUtil.get(`/view/view/get/user/${userId}/self_only`, params);
};

// 我是组长图谱列表，不包含协作
export const API_GetMyIsMasterViewList = (userId, params) => {
  //return httpUtil.get(`/view/view/get/user/${userId}/as_owner`, params);
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=owner&limit=60`, params)
  return httpUtil.get(`/map_v2/map/list/${userId}/as_owner`, params);
};

// 我是成员图谱列表，不包含创建的
export const API_GetMyIsMemberViewList = (userId, params) => {
  return httpUtil.get(`/view/view/get/user/${userId}/as_member`, params);
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=member&limit=60`, params)
};

// （我的）协作图谱列表
export const API_GetTeamworkViewList = (userId, params) => {
  return httpUtil.get(`/view/view/get/user/${userId}/teamwork_only`, params);
};

// 推荐图谱列表
export const API_GetRecommendView = (params) => {
  return httpUtil.get(`/view/view/get/recommend`, { params });
};

// 设置图谱推荐状态
export const API_RecommendView = (viewId, recommend, params) => {
  return httpUtil.get(`/view/view/recommend/${viewId}/${recommend}`, {
    params,
  });
};

// 通过名称搜索图谱
export const API_GetSearchViewList = ({ q, limit, teamwork_type, channel = 0, tag = undefined }) => {
  return httpUtil.get(`/map_v2/map/search/by_view_name`, {
    q,
    limit,
    teamwork_type,
    channel,
    tag
  });
};

// 通过看板内节点搜索看板
export const API_GetSearchViewListByNode = ({ q, limit }) => {
  return httpUtil.get(`/view/view/search/by_node_in_view`, { q, limit });
};

// 收费图谱下单
export const API_OrderView = (viewId) => {
  return httpUtil.get(`/view/trans/buy/${viewId}`);
};

// 购买免费图谱
export const API_OrderFreeView = (viewId) => {
  //return httpUtil.get(`/view/trans/buy/free/${viewId}`);
  return httpUtil.get(`/map_v2/map/trans/buy/free/${viewId}`);
};

// 查询订单
export const API_QueryOrder = (orderId) => {
  return httpUtil.get(`/view/trans/query/${orderId}`);
};

// 对通过子图的训练结果投票
export const API_VoteExplorationResultBySubview = (
  viewId,
  subViewId,
  subViewVersion,
  alg,
  resultId,
  vote
) => {
  return httpUtil.put(
    `/view/graph/re/grow/vote/${viewId}/${subViewId}/${subViewVersion}/2/${alg}/${resultId}/${vote}`
  );
};

// 对通过资源的训练结果投票
export const API_VoteExplorationResultByResource = (
  viewId,
  resourceId,
  alg,
  resultId,
  vote
) => {
  return httpUtil.put(
    `/view/graph/re/grow/vote/${viewId}/${resourceId}/0/9/${alg}/${resultId}/${vote}`
  );
};

// 获取单节点详情洞察结果
export const API_InsightDetailInfoByNode = (nodeId, nodeType) => {
  return httpUtil.get(
    `/view/graph/re/insight/detail/${nodeId}/${nodeType}/info`
  );
};

// 获取单节点属性洞察结果
export const API_InsightDetailPropertiesByNode = (
  nodeId,
  nodeType,
  preferredType,
  relatedTags
) => {
  return httpUtil.get(
    `/view/graph/re/insight/detail/${nodeId}/${nodeType}/properties`,
    { preferredType, relatedTags: relatedTags.join(",") }
  );
};

// 获取单节点时间线洞察结果
export const API_InsightDetailTimelineByNode = (
  nodeId,
  nodeType,
  preferredType,
  relatedTags
) => {
  return httpUtil.get(
    `/view/graph/re/insight/detail/${nodeId}/${nodeType}/timeline`,
    { preferredType, relatedTags: relatedTags.join(",") }
  );
};

// 获取单节点关系洞察结果
export const API_InsightDetailRelationByNode = (
  nodeId,
  nodeType,
  preferredType,
  relatedTags,
  override
) => {
  return httpUtil.get(
    `/view/graph/re/insight/detail/${nodeId}/${nodeType}/relation`,
    {
      preferredType,
      relatedTags: relatedTags.join(","),
      override: JSON.stringify(override),
    }
  );
};

// 获取单节点图片洞察结果
export const API_InsightDetailImagesByNode = (
  nodeId,
  nodeType,
  preferredType,
  relatedTags
) => {
  return httpUtil.get(
    `/view/graph/re/insight/detail/${nodeId}/${nodeType}/images`,
    { preferredType, relatedTags: relatedTags.join(",") }
  );
};

// 获取协作成员列表
export const API_GetTeamworkMembers = (
  viewId,
  limit,
  orderBy,
  orderType,
  start
) => {
  return httpUtil.get(`/view/view/teamwork_member/${viewId}`, {
    limit,
    orderBy,
    orderType,
    start,
  });
};

// 添加图谱协作成员
export const API_AddTeamworkMember = (viewId, userId, token) => {
  return httpUtil.post(`/view/view/teamwork_member/${viewId}/${userId}`, null, {
    token,
  });
};

// 移除图谱协作成员
export const API_RemoveTeamworkMember = (viewId, userId) => {
  return httpUtil.delete(`/view/view/teamwork_member/${viewId}/${userId}`);
};

// 获取图谱协作配置
export const API_LoadTeamworkSettings = (viewId, key = undefined) => {
  return httpUtil.get(`/view/view/${viewId}/teamwork_settings`, { key });
};

// 修改图谱协作配置
export const API_SetTeamworkSettings = (
  viewId,
  type,
  auto_set_sharing_type = 1
) => {
  return httpUtil.put(
    `/view/view/${viewId}/teamwork_settings`,
    {},
    { type, auto_set_sharing_type }
  );
};

// 部分修改图谱协作配置
export const API_SetTeamworkSettingsPartially = (
  viewId,
  type = undefined,
  auto_set_sharing_type = 1,
  meta = {}
) => {
  return httpUtil.patch(`/view/view/${viewId}/teamwork_settings`, meta, {
    type,
    auto_set_sharing_type,
  });
};

// 将一个用户的所有图谱相关信息转移到另一个用户中
export const API_MoveViewInfoByAccessToken = (accessToken) => {
  return httpUtil.put(`/view/view/move_by_access_token/${accessToken}`);
};

// 将一个用户的所有交易相关信息转移到另一个用户中
export const API_MoveTransInfoByAccessToken = (accessToken) => {
  return httpUtil.put(`/view/trans/move_by_access_token/${accessToken}`);
};

// 获取单节点匹配标签(词云)
export const API_MatchTagsByNode = (
  viewId,
  nodeId,
  text = undefined,
  flag = 0
) => {
  return httpUtil.get(`/view/graph/re/match/${viewId}/${nodeId}/tags`, {
    text,
    flag: `${flag}`,
  });
};

// 获取单节点匹配资源
export const API_MatchResourcesByNode = (viewId, nodeId) => {
  return httpUtil.get(`/view/graph/re/match/${viewId}/${nodeId}/resources`);
};

// 添加简单关系至图谱
export const API_AddRelationGraph = (
  viewId,
  nodes,
  edges,
  testOnly = false,
  doAnalyze = true
) => {
  //return httpUtil.post(`/view/relation/${viewId}/sub_graph`,{ nodes, edges },{ test_only: testOnly ? 1 : 0, do_analyze: doAnalyze ? 1 : 0 });
  return httpUtil.post(`/map_v2/relation/map/${viewId}/sub_graph/add`,{ nodes, edges },{ test_only: testOnly ? 1 : 0, do_analyze: doAnalyze ? 1 : 0 });
};

// 加载图谱（或图谱节点）的简报信息
export const API_LoadBriefingInfoList = (viewId, nodeIds) => {
  return httpUtil.get(`/view/relation/${viewId}/briefing`, {
    node_ids: nodeIds && nodeIds.length > 0 ? nodeIds.join(",") : undefined,
  });
};

// 加载指定简报信息
export const API_LoadBriefingInfo = (briefingId) => {
  return httpUtil.get(`/view/relation/briefing/${briefingId}`);
};

// 获取图谱封面
export const API_GetViewCover = (viewId) => {
  return httpUtil.get(`/view/view/${viewId}/cover`);
};

// 获取公开图谱封面
export const API_GetViewPublicCover = (viewId) => {
  return httpUtil.get(`/view/public/view/${viewId}/cover`);
};

// 设置图谱封面
export const API_SetViewCover = (viewId, coverData = undefined) => {
  return httpUtil.post(
    `/view/view/${viewId}/cover`,
    coverData ? { data: coverData } : {}
  );
};

// 通过用户ID获取节点列表
export const API_LoadNodeListByUser = (userId, afterDate) => {
  let params = { user_id: userId };
  if (afterDate) params["update_time_after_equal"] = afterDate;
  return httpUtil.get(`/view/relation/public_node`, params);
};

/**
 * 批量获取节点数量统计信息
 *
 * @param {NodeAmountStatisticsRequest[]} statisticsRequestList
 * @return {Promise}
 */
export const API_BatchLoadNodeAmountStatistics = (statisticsRequestList) => {
  return httpUtil.post(
    `/view/relation/node/actions/amount_statistics`,
    statisticsRequestList
  );
};

export const API_AccessNode = (viewId, nodeId) => {
  return httpUtil.get(`/view/relation/${viewId}/node/${nodeId}/actions/access`);
};

export const API_LoadViewConfig = (viewId, key, forDisplayOnly = true) => {
  return httpUtil.get(`/view/view/${viewId}/config/${key}`, {
    for_display_only: forDisplayOnly ? 1 : 0,
  });
};

export const API_UpdateViewConfig = (
  viewId,
  key,
  config,
  replaceAll = true
) => {
  return replaceAll
    ? httpUtil.post(`/view/view/${viewId}/config/${key}`, config)
    : httpUtil.put(`/view/view/${viewId}/config/${key}`, config);
};

export const API_LoadViewUserConfig = (viewId, key, forDisplayOnly = true) => {
  return httpUtil.get(`/view/view/${viewId}/user_config/${key}`, {
    for_display_only: forDisplayOnly ? 1 : 0,
  });
};

export const API_UpdateViewUserConfig = (
  viewId,
  key,
  config,
  replaceAll = true
) => {
  return replaceAll
    ? httpUtil.post(`/view/view/${viewId}/user_config/${key}`, config)
    : httpUtil.put(`/view/view/${viewId}/user_config/${key}`, config);
};

export const API_LoadViewIndexValue = (
  viewId,
  filter,
  alg,
  hash,
  modifiedNodeIds
) => {
  return httpUtil.post(`/view/relation/${viewId}/index/value`, {
    filter,
    alg,
    hash,
    modifiedNodeIds,
  });
};

export const API_ExploreAsync = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/async`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

export const API_ExploreAsyncByNum = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/async/by_num`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

export const API_ExploreAsyncByProps = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/async/by_props`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

export const API_ExploreSyncByConnection = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/sync/by_connection`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

export const API_ExploreSyncBySimilarity = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/sync/by_similarity`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

export const API_ExploreSyncByDomainPerson = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/explore/${viewId}/sync/by_domain_person`,
    nodeId ? { node_id: nodeId } : undefined
  );
};

// 将图谱转到指定用户下
export const API_ViewTransferToUser = (viewId, userId) => {
  return httpUtil.put(`/view/view/${viewId}/transfer/to_user/${userId}`);
};

// 获取图谱标签列表
export const API_GetViewTags = ({ q, start, limit }) => {
  /* 20230921 因为此接口太慢，停用
  return httpUtilWithMsg.get(`/view/view/tags`, {
    q,
    start,
    limit,
    axios: { loading_msg: false },
  });*/
};

// 批量删除节点
export const API_RemoveNodes = (viewId, nodeIds) => {
  //return httpUtil.post(`/view/relation/${viewId}/node/actions/batch_remove`,nodeIds.join(","));
  return httpUtil.post(`/map_v2/relation/map/${viewId}/node/actions/batch_remove`,{nodeIds:nodeIds.join(",")});
};

// 批量更新关联关系信息
export const API_UpdateEdgeInfoList = (viewId, edgeInfoList) => {
  //return httpUtil.put(`/view/relation/${viewId}/edge`, edgeInfoList);
  return httpUtil.put(`/map_v2/relation/map/${viewId}/edge`, edgeInfoList);
};

// 引力场搜索
export const API_SmartSearchInView = (viewId, keyword) => {
  return httpUtil.get(`/view/relation/${viewId}/actions/smart_search`, {
    keyword,
  });
};

/**
   * 批量更新节点信息
   *
   * @param {{id: string, [x]: Number, [y]: Number, [fixed]: Boolean, [meta]: Object}[]} nodeList
   * @param {boolean} refreshUpdate 更新修改时间
   */
export const API_BatchUpdateNodeInfoPartially = (
  viewId,
  nodeList,
  refreshUpdate
) => {
  //return httpUtil.patch(`/view/relation/${viewId}/node`, nodeList, {refresh_update_time: refreshUpdate ? 1 : 0,});
  return httpUtil.patch(`/map_v2/relation/map/${viewId}/node`, nodeList, {refresh_update_time: refreshUpdate ? 1 : 0,});
};

export const API_LoadRankData = (viewId, type) => {
  return httpUtil.get(`/view/view/${viewId}/rank_data`, { type });
};

export const API_LoadRankData_V2 = (params) => {
  return httpUtil.get(`/g08/v1web/analysis/evnt_query/get_kws_of_map`,params);
};

// 获取看版内相关节点ID列表
export const API_GravityNodeIdListInView = (viewId, nodeId) => {
  return httpUtil.get(`/view/graph/re/gravity_in_view/${viewId}/${nodeId}`);
};
// 获取看版内相关节点ID列表 --新接口
export const API_SearchWordListInView = (viewId, content, size = 5) => {
  return httpUtil.get(
    `/view/graph/re/search_word_in_view/${viewId}/${content}`,
    { size }
  );
};

export const API_CutWord = (content) => {
  return httpUtil.get(`/view/graph/re/cut_word/${content}`);
};

// 锁定图谱内容
export const API_LockView = (viewId) => {
  return httpUtil.patch(`/view/view/${viewId}/actions/lock`);
};

// 解锁图谱内容
export const API_UnlockView = (viewId) => {
  return httpUtil.patch(`/view/view/${viewId}/actions/unlock`);
};

// 获取关系主要结构子图
export const API_GetViewStructureGraph = (viewId, limit) => {
  return httpUtil.get(`/view/relation/${viewId}/structure_graph`, {
    viewId,
    limit,
  });
};

// 创建图谱相关的用户奖章
export const API_SetViewBadge = ({
  viewId,
  userId,
  nodeId,
  nodeFname,
  text,
  type,
}) => {
  return httpUtil.post(`/view/view/badge`, {
    viewId,
    userId,
    nodeId,
    nodeFname,
    text,
    type,
  });
};

export const API_SmartSearchGraphInAllView = (viewId, keyword) => {
  return httpUtil.get(`/view/relation/${viewId}/actions/smart_search_graph`, {
    keyword,
  });
};

export const API_SmartSearchNodeInAllView = (
  viewId,
  keyword,
  limit,
  vrVisibleType
) => {
  return httpUtil.get(`/view/relation/${viewId}/actions/smart_search_node`, {
    keyword,
    limit,
    vr_visible_type: vrVisibleType,
  });
};

export const API_SmartSearchUserInAllView = (viewId, keyword, nodeId) => {
  return httpUtil.get(`/view/relation/${viewId}/actions/smart_search_user`, {
    keyword,
    node_id: nodeId,
  });
};

export const API_RecommendByView = (viewId, resultType) => {
  return httpUtil.get(`/view/relation/${viewId}/actions/recommend_by_view`, {
    result_type: resultType,
  });
};

// 恢复图谱内关系图
export const API_RecoverRelationGraph = (viewId, nodeKeys, edgeKeys) => {
  //return httpUtil.put(`/view/relation/${viewId}/sub_graph/actions/recover`, {nodeKeys,edgeKeys});
  return httpUtil.put(`/map_v2/relation/map/${viewId}/sub_graph/actions/recover`, {nodeKeys,edgeKeys});
};

// 删除图谱内关系图
export const API_RemoveRelationGraph = (viewId, nodeKeys, edgeKeys) => {
  //return httpUtil.put(`/view/relation/${viewId}/sub_graph/actions/remove`, {nodeKeys,edgeKeys});
  return httpUtil.put(`/map_v2/relation/map/${viewId}/sub_graph/actions/remove`, {nodeKeys,edgeKeys});
};

export const API_LoadNodeTaskList = (parameters) => {
  return httpUtil.get(`/view/task`, parameters);
};

export const API_CreateNodeTask = (task) => {
  return httpUtil.post(`/view/task`, task);
};

export const API_LoadNodeTask = (nodeTaskId) => {
  return httpUtil.get(`/view/task/${nodeTaskId}`);
};

export const API_UpdateNodeTask = (task) => {
  return httpUtil.put(`/view/task/${task["nodeTaskId"]}`, task);
};

export const API_PatchNodeTask = (task) => {
  return httpUtil.patch(`/view/task/${task["nodeTaskId"]}`, task);
};

export const API_DoneNodeTask = (nodeTaskId) => {
  return httpUtil.patch(`/view/task/${nodeTaskId}/actions/complete`);
};

export const API_RecommendCompanyByView = (viewId, start = 0, limit = 10) => {
  return httpUtil.get(`/view/calc/recommend_company_by_view/${viewId}`, {
    start,
    limit,
  });
};

export const API_ExploreRelatedNodeByNode = (nodeInfo) => {
  return httpUtil.post(`/view/calc/explore_related_node_by_node`, nodeInfo);
};

export const API_ExploreOverrideByNode = (nodeInfo) => {
  return httpUtil.post(`/view/calc/explore_override_by_node`, nodeInfo);
};

export const API_HasSubGraphSnapshot = (viewId, params) => {
  return httpUtil.get(`/view/relation/${viewId}/sub_graph/has_snapshot`, {
    viewId,
    ...params,
  });
};

// 获取关系图结构信息
export const API_GetRelationStructure = (viewId, limit = -1) => {
  //return httpUtil.get(`/view/relation/${viewId}/edge_with_node_position`, {limit});
  //return httpUtil.get(`/map_v2/relation/map/${viewId}/edge_with_node_position`, {limit});
  const fingerprint = getClientJSFingerprint();
  //return httpUtil.get(`/map_v2_dev/relation/map/${viewId}/edge_with_node_position`, {limit,fingerprint});
  return httpUtil.get(`/map_v2/relation/map/${viewId}/edge_with_node_position`, {limit,fingerprint});
};

// 获取关系图节点列表
export const API_GetRelationNodeList = (
  viewId,
  conditions = {},
  start = 0,
  limit = -1,
  orderBy,
  orderType
) => {
  return httpUtil.get(`/map_v2/relation/map/${viewId}/node`, {
    ...conditions,
    start,
    limit,
    orderBy,
    orderType,
  });
};
/*
export const API_GetRelationNodeList = (
  viewId,
  conditions = {},
  start = 0,
  limit = -1,
  orderBy,
  orderType
) => {
  return httpUtil.get(`/view/relation/${viewId}/node`, {
    ...conditions,
    start,
    limit,
    orderBy,
    orderType,
  });
};
*/

export const API_GetRelationNodeListByIds = (viewId,nodeIds,params) => {
  return httpUtil.post(`/view/relation/${viewId}/node/get_by_ids`, nodeIds, {...params});
};

export const API_AccessView = (viewId) => {
  return httpUtil.get(`/view/view/${viewId}/actions/access`);
};

export const API_DoMicroServiceByView = (
  viewId,
  servicePath,
  target,
  parameters,
  start,
  limit
) => {
  return httpUtil.post(
    `/view/calc/do_micro_service_by_view/${servicePath}/${viewId}`,
    { target, parameters, start, limit }
  );
};

export const API_LoadTeamworkTickets = (viewId, parameters) => {
  return httpUtil.get(`/view/view/teamwork_ticket`, {
    view_id: viewId,
    ...parameters,
  });
};

export const API_AddTeamworkTicket = (viewId, config) => {
  return httpUtil.post(`/view/view/teamwork_ticket/${viewId}`, config);
};

export const API_RemoveTeamworkTicket = (viewId, code) => {
  return httpUtil.delete(`/view/view/teamwork_ticket/${viewId}/${code}`);
};

export const API_RemoveNoneFixedNodePositionsByView = (viewId) => {
  //return httpUtil.delete(`/view/relation/${viewId}/node/actions/remove_none_fixed_position`);
  return httpUtil.delete(`/map_v2/relation/map/${viewId}/node/actions/remove_none_fixed_position`);
};

// 跨看板获取节点
export const API_GraphNodeList = (viewId, nodeId) => {
  return httpUtil.get(
    `/view/graph/re/node/list/by_query?view_id=${viewId}&node_id=${nodeId}`
  );
};

export const API_GetSubGraph = (viewId, params) => {
  //return httpUtil.get(`/view/relation/${viewId}/sub_graph`, {viewId,...params,});
  return httpUtil.get(`/map_v2/relation/map/${viewId}/sub_graph`, {viewId,...params,});
};

/**
 * 添加节点的子弹评估
 * @param {*} viewId
 * @param {*} nodeId
 * @param {*} params
 * @returns {code:0 | -1, msg:'xxxx', data:insert_id}
 */
export const API_AddNodeAssess = (viewId, nodeId, data, params) => {
  return httpUtil.post(`/view/assess`, {
    viewId,
    nodeId,
    ...data,
    ...params,
  });
};

/**
 * 查询节点的子弹评估列表
 * 列表项就是详情
 * 支持分页
 * @param {*} viewId
 * @param {*} nodeId
 * @param {*} params
 * @returns {code:0 | -1, msg:'xxxx', data:分页内评估列表, total:该节点的评估总数}
 */
export const API_GetNodeAssessList = (viewId, nodeId, params) => {
  return httpUtil.get(`/view/assess`, {
    viewId,
    nodeId,
    ...params,
  });
};

export const API_getNodeListByCondition = (viewId, params) => {
  return httpUtil.get(`/map_v2/relation/map/${viewId}/node_by_condition`, {
    conditions:params.conditions||{},
    keyword:params.keyword,
    start:params.start,
    limit:params.limit,
    orderBy:params.orderBy,
    orderType:params.orderType,
  });
};
