import axios from "axios";
import {
  showErrorMsg,
  showPageLoading,
} from "@/components/common/common.message";

import { ClientJS } from 'clientjs';

const service = axios.create({
  timeout: 1500000, // 超时时间
  headers: {
    // 'X-Custom-Header': 'foobar',
    "x-requested-with": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Max-Age": 0,
    "MAPKEY": "fc5430ee-c16f-4d53-9b33-8b01d0c9b9e2"
  },
});

const paramsSerializer = (params) => {
  const toString = Object.prototype.toString;

  const encode = (val) => {
    return encodeURIComponent(val)
      .replace(/%40/gi, "@")
      .replace(/%3A/gi, ":")
      .replace(/%24/g, "$")
      .replace(/%2C/gi, ",")
      .replace(/%20/g, "+")
      .replace(/%5B/gi, "[")
      .replace(/%5D/gi, "]");
  };

  const forEach = (obj, fn) => {
    // Don't bother if no value provided
    if (obj === null || typeof obj === "undefined") {
      return;
    }

    // Force an array if not already something iterable
    if (typeof obj !== "object") {
      /*eslint no-param-reassign:0*/
      obj = [obj];
    }

    if (toString.call(obj) === "[object Array]") {
      // Iterate over array values
      for (let i = 0, l = obj.length; i < l; i++) {
        fn.call(null, obj[i], i, obj);
      }
    } else {
      // Iterate over object keys
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          fn.call(null, obj[key], key, obj);
        }
      }
    }
  };

  let parts = [];
  forEach(params, (val, key) => {
    if (val === null || typeof val === "undefined") {
      return;
    }

    if (toString.call(val) === "[object Array]") {
      key = key + "[]";
    } else {
      val = [val];
    }

    forEach(val, (v) => {
      if (toString.call(v) === "[object Date]") {
        v = v.toISOString();
      } else if (v !== null && typeof v === "object") {
        v = JSON.stringify(v);
      }
      parts.push(encode(key) + "=" + encodeURIComponent(v));
    });
  });

  return parts.join("&");
};

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // 获取token
    config.headers.Authorization = localStorage.getItem("token") || "";
    config.paramsSerializer = paramsSerializer;

    // 在发送请求之前做些什么
    let requestConfig =
      config.data && config.data["axios"]
        ? config.data["axios"]
        : config.params && config.params["axios"]
        ? config.params["axios"]
        : {};
    let loadingMsg =
      requestConfig["loading_msg"] !== undefined
        ? requestConfig["loading_msg"]
        : undefined;
    let asAjax =
      requestConfig["asAjax"] !== undefined
        ? requestConfig["asAjax"]
        : undefined;

    if (typeof config.data === "string")
      config.headers["Content-Type"] = "text/plain";

    let hideMessage = () => {};
    if (loadingMsg !== false) {
      hideMessage = showPageLoading(loadingMsg, asAjax);
    }

    config.validateStatus = function (status) {
      // 每个请求都会调用validateStatus函数，因此临时将隐藏消息框的操作写在这里
      hideMessage();
      return status >= 200 && status < 300; // default
    };
    // Dismiss manually and asynchronously
    // setTimeout(hide, 2500);
    // 继续请求
    return config;
  },
  (error) => {
    showErrorMsg({
      msg: "没有收到后台响应，请稍后重试操作，如果仍然出现问题，请将情况反馈给我们，我们的邮箱：help@joinmap.ai。",
    });
    // 其他业务
    console.log(
      "[HttpUtil.js->service->request][1] 请求失败，你的代码可能已经狗带，异常:",
      error
    );
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log('[utils.js->service->response] response.data.code:', response.data.code);
    if (response.data.token) localStorage.setItem("token", response.data.token); // 保存token
    if (typeof response.config["data"] === "string") {
      // 字符串解析成json对象
      try {
        response.config["data"] = JSON.parse(response.config["data"].trim());
      } catch (e) {
        /* Ignore 解析出错则忽略 */
        console.log("can not JSON.parse the response", e);
      }
    }
    let requestConfig = {};
    if (response.config.data && typeof response.config["data"] !== "string") {
      requestConfig = { ...response, ...response.config.data };
    }
    if (response.config.params) {
      requestConfig = { ...response, ...response.config.params };
    }
    if (
      (requestConfig &&
        requestConfig["axios"] &&
        requestConfig["axios"]["custom_error_handling"] === 1) ||
      response.data.code === 0
    ) {
      // 无错误或自行处理错误，继续响应
      return response;
    } else {
      // 对错误码进行处理
      switch (response.data.code) {
        case 30001:
          // 账号未注册
          if (Object.keys(response.data).includes("thirdPartUser")) {
            localStorage.setItem(
              "third",
              JSON.stringify(response.data["thirdPartUser"])
            );
          }
          showErrorMsg(response.data);
          break;
        case 31001:
        case 31002:
        case 31003:
          localStorage.clear();
          sessionStorage.clear();
          showErrorMsg(
            { msg: "登录信息过期，请重新登录", code: 0 /* TODO */ },
            1
          ).then(() => (window.location.href = "/login"));
          break;
        default:
          showErrorMsg(response.data);
      }
      return Promise.reject(response.data.code || response.data.status);
    }
  },
  (error) => {
    let requestConfig = {};
    if (
      error &&
      error.hasOwnProperty("config") &&
      error.config.hasOwnProperty("data") &&
      error.config.data
    ) {
      requestConfig = { ...requestConfig, ...error.config.data };
    }
    if (
      error &&
      error.hasOwnProperty("config") &&
      error.config.hasOwnProperty("params") &&
      error.config.params
    ) {
      requestConfig = { ...requestConfig, ...error.config.params };
    }
    if (
      requestConfig &&
      requestConfig["axios"] &&
      requestConfig["axios"]["custom_error_handling"] === 1
    ) {
      // 自行处理错误
    } else {
      showErrorMsg({
        msg: "没有收到后台响应，请重试上步操作，仍然出现问题，请联系我们，邮箱：help@joinmap.ai。",
      });
      console.log(
        "[HttpUtil.js->service->response][2] 响应失败，服务器可能已经狗带，异常:",
        error
      );
    }
    // 对响应错误做点什么
    if (error && error.response) {
      if (error.response.data) {
        error = error.response.data.code || error.response.data.status;
      } else {
        error = error.response.status;
      }
    }
    return Promise.reject(error);
  }
);

// 请求前缀
export const REQUEST_BASE = "/base";

// token
export const getToken = () => localStorage.getItem("token");
export const removeToken = () => localStorage.setItem("token", "");

export const getHttpUtil = (withMsg = true, base = REQUEST_BASE) => {
  return {
    get: (url, params) => {
      if (!withMsg) {
        params = params || {};
        params.axios = {
          loading_msg: false,
          custom_error_handling: 1,
          ...(params.axios || {}),
        };
      }
      return service({
        method: "get",
        url: base + url,
        params,
      });
    },
    post: (url, data, params, progressCB, cancelToken) => {
      if (!withMsg) {
        params = params || {};
        params.axios = {
          loading_msg: false,
          custom_error_handling: 1,
          ...(params.axios || {}),
        };
      }
      return service({
        method: "post",
        url: base + url,
        data,
        params,
        onUploadProgress: progressCB,
        cancelToken,
      });
    },
    put: (url, data, params) => {
      if (!withMsg) {
        params = params || {};
        params.axios = {
          loading_msg: false,
          custom_error_handling: 1,
          ...(params.axios || {}),
        };
      }
      return service({
        method: "put",
        url: base + url,
        data,
        params,
      });
    },
    patch: (url, data, params) => {
      if (!withMsg) {
        params = params || {};
        params.axios = {
          loading_msg: false,
          custom_error_handling: 1,
          ...(params.axios || {}),
        };
      }
      return service({
        method: "patch",
        url: base + url,
        data,
        params,
      });
    },
    delete: (url, params) => {
      if (!withMsg) {
        params = params || {};
        params.axios = {
          loading_msg: false,
          custom_error_handling: 1,
          ...(params.axios || {}),
        };
      }
      return service({
        method: "delete",
        url: base + url,
        params,
      });
    },
  };
};

export default getHttpUtil();

export const httpUtilWithNoMsg = getHttpUtil(false);

export const httpUtilWithExtend = getHttpUtil(false, "/extends");

export const httpUtilC = {
  base: "http://www.joinmap.ai:9449",
  get: (url, params) => {
    params = params || {};
    params.axios = {
      loading_msg: false,
      ...(params.axios || {}),
    };
    return service({
      method: "get",
      url: httpUtilC.base + url,
      params,
    });
  },
  post: (url, data, params) => {
    params = params || {};
    params.axios = {
      loading_msg: false,
      ...(params.axios || {}),
    };
    return service({
      method: "post",
      url: httpUtilC.base + url,
      data,
      params,
    });
  },
  put: (url, data, params) => {
    params = params || {};
    params.axios = {
      loading_msg: false,
      ...(params.axios || {}),
    };
    return service({
      method: "put",
      url: httpUtilC.base + url,
      data,
      params,
    });
  },
};

export const getClientJSFingerprint = () => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();// 浏览器指纹
  return fingerprint;
  /*
  client.getBrowserData();
  client.getFingerprint();
  client.getCustomFingerprint(...);

  client.getUserAgent();
  client.getUserAgentLowerCase();

  client.getBrowser();
  client.getBrowserVersion();
  client.getBrowserMajorVersion();
  client.isIE();
  client.isChrome();
  client.isFirefox();
  client.isSafari();
  client.isOpera();

  client.getEngine();
  client.getEngineVersion();

  client.getOS();
  client.getOSVersion();
  client.isWindows();
  client.isMac();
  client.isLinux();
  client.isUbuntu();
  client.isSolaris();

  client.getDevice();
  client.getDeviceType();
  client.getDeviceVendor();

  client.getCPU();

  client.isMobile();
  client.isMobileMajor();
  client.isMobileAndroid();
  client.isMobileOpera();
  client.isMobileWindows();
  client.isMobileBlackBerry();

  client.isMobileIOS();
  client.isIphone();
  client.isIpad();
  client.isIpod();

  client.getScreenPrint();
  client.getColorDepth();
  client.getCurrentResolution();
  client.getAvailableResolution();
  client.getDeviceXDPI();
  client.getDeviceYDPI();

  client.getPlugins();
  client.isJava();
  client.getJavaVersion(); // functional only in java and full builds, throws an error otherwise
  client.isFlash();
  client.getFlashVersion(); // functional only in flash and full builds, throws an error otherwise
  client.isSilverlight();
  client.getSilverlightVersion();

  client.getMimeTypes();
  client.isMimeTypes();

  client.isFont();
  client.getFonts();

  client.isLocalStorage();
  client.isSessionStorage();
  client.isCookie();

  client.getTimeZone();

  client.getLanguage();
  client.getSystemLanguage();

  client.isCanvas();
  client.getCanvasPrint();

  //const browser = client.getBrowser();
  //return `Browser Name: ${browser.name}, Version: ${browser.version}`;
  */
};
