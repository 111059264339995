import {httpUtilWithNoMsg as httpUtil} from '@/utils/HttpUtil';

export const API_SendFeedback = (feedback, files) => {
  return httpUtil.post(`/view/project/feedback`, {feedback, files});
};

export const API_MatchUser = ({nick, name, email, mobile, accessToken}) => {
  return httpUtil.get(`/user/user/match`, {nick, name, email, mobile, accessToken})
};

// 将一个用户的所有用户相关信息转移到另一个用户中
export const API_MoveUserInfoByAccessToken = accessToken => {
  return httpUtil.put(`/user/user/move_by_access_token/${accessToken}`);
};

// 通过token验证邮箱
export const API_VerifyEmailByToken = token => {
  return httpUtil.put(`/user/username/email/verify`, undefined, {token});
};

// 修改绑定邮箱
export const API_ChangeEmail = (email, password) => {
  return httpUtil.put(`/user/username/email/code`, undefined, {email, password});
};

// 获取图谱相关的用户奖章
export const API_GetViewBadge = ({userId, start, limit, orderType, orderBy}) => {
  return httpUtil.get(`/view/view/badge`, {user_id: userId, start, limit, order_by: orderBy, order_type: orderType});
};

// 获取图谱操作记录统计
export const API_GetViewActionLogSummary = ({userId}) => {
  return httpUtil.get(`/view/view/action_log_summary`, {user_id: userId});
};

// 通过Id找人物信息
export const API_GetUserInfoById = (userId) => {
  return httpUtil.get(`/user/user/${userId}`, {id: userId});
};

// 获取参与的图谱列表
export const API_GetEditableViewByUserId = (userId) => {
  return httpUtil.get(`/view/view/list/editable`, {user_id: userId});
};

// 获取节点公开信息列表
export const API_GetViewPublicNode = ({user_id, start, limit, order_by, order_type, with_detail}) => {
  return httpUtil.get(`/view/relation/public_node`, {user_id, start, limit, order_by, order_type, with_detail});
};

export const API_TradeOrderCreat = (param) => {
  //return httpUtil.post(`/map_v2_dev/trade_order/order_creat`, param);
  return httpUtil.post(`/map_v2/trade_pay_wx/order_creat`, param);
};

export const API_TradeOrderCheckState = (param) => {
  return httpUtil.post(`/map_v2/trade_pay_wx/order_check`, param);
};

export const API_UserLevelChanger = (param) => {
  return httpUtil.post(`/map_v2/trade_pay_wx/do_user_level_changer`, param);
};

export const API_getUserLevel = () => {
  return httpUtil.get(`/map_v2/user/get_user_level`);
};