import React from 'react';
import {connect} from "react-redux";

import style from '@/style/components/common.loginRelatedView.less';
import LoginRelatedView from '@/components/common/common.loginRelatedView';
import CheckUsernameForm from '@/components/loginView/login.checkUsernameForm';
import CheckPasswordForm from '@/components/loginView/login.checkPasswordForm';
import LoginViaWechatPanel from '@/components/loginView/login.loginViaWechatPanel';
import {
  backToCheckUsernameAction,
  doCheckUsernameAction,
  doCheckWeChatFwhTicketAction,
  doGetWeChatFwhTicketAction,
  doLoginAction,
  doLoginViaWeChatAction,
  doMagicLoginAction,
  initLoginViaUsernameAction,
  initLoginViaWechatAction,
  initRegisterAction,
  onWeChatResourceLoadedAction,
  resetPwdConfirmAction,
  resetPwdViaEmailAction,
  userFoundAction,
} from '@/redux-saga/account/actions';
import {AuthStepEnum, LoginStatusEnum, NormalLoginStatusEnum} from "@/constants/account";
import EmailSentNoticePanel from "@/components/common/common.emailSentNoticePanel";
import ResetPwdConfirmPanel from "@/components/loginView/login.resetPwdConfirmPanel";
import qs from "qs";

class LoginView extends React.Component {
  state = {
    redirectToLoginAt: 0,
    redirectToPath: window.location.hostname==="ustcsv.joinmap.ai"?'/channel/ustcsv/teamwork':'/mainview/dashboard/public'.toLowerCase(), // 登陆成功后跳转路径 /mainView
    tryMagicLogin: localStorage.getItem('try_magic_login') !== '0', // 尝试自动登录，特定项目使用
    loginFlag: false,
    showUserAgreementModal:false,
    showPrivacyPolicyModal:false
  };

  checkPassword = (password, remember) => this.props.login(this.props.existedUsername, password, remember);

  resetPwdViaEmail = () => this.props.resetPwdViaEmail(this.props.existedUsername);

  gotoRegister = (e) => {
    e.preventDefault();
    this.props.initRegister();
    this.props.history.push('/register');
  };

  gotoLoginViaWechatQRCode = () => {
    this.props.initWechatQRCode((dispatch) => {
      dispatch(initLoginViaUsernameAction())
    });
  };

  onInputFocus = (e) => {
    e.target.previousSibling.firstElementChild.style.color = '#404040';
  };

  onInputBlur = (e) => {
    e.target.previousSibling.firstElementChild.style.color = '';
  };

  onCheckUsername = (username) => {
    if (this.props.existedUsername && this.props.existedUsername === username) {
      this.props.gotoCheckPassword(username);
    } else {
      this.props.checkUsername(username);
    }
  };

  backToCheckPassword = () => {
    this.props.gotoCheckPassword(this.props.existedUsername);
  };

  initLoginViaUsername = () => {
    this.setState({redirectToLoginAt: 0}, () => this.props.initLoginViaUsername())
  };

  guestLogin = () => {
    //localStorage.setItem("token", "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJob21lX2d1ZXN0QGpvaW5tYXAuYWkiLCJjb250ZXh0VXNlcklkIjoiMTk0IiwiY29udGV4dFVzZXJUeXBlIjoxLCJjb250ZXh0TG9naW5UeXBlIjoiRU1BSUwiLCJjb250ZXh0TG9naW5PcGVuSWQiOiIiLCJjb250ZXh0U2Vzc2lvbklkIjoiODk5MmMzMTItYWE3Ni00NzE1LTk2MjAtYTU0MjExMGIxM2FkIiwicmVuZXdhbFRpbWUiOjE3MjQxODAxMzM1NzYsImV4cCI6MTcyNDIyMzMzM30.0uZdjQQQGCnHcYkyYyQ3A_VP76gUM-v5jngxN888rIQ");
    //this.props.login('home_guest@joinmap.ai', 'Gruefx%x', 7);
    this.props.magicLogin();
    //setTimeout(() => {this.props.history.push('/mainview/dashboard/public')}, 2000);
  };

  userAgreement  = () => {
    this.setState({showUserAgreementModal:true})
  }

  privacyPolicy  = () => {
    this.setState({showPrivacyPolicyModal:true})
  }

  componentWillMount() {
    const {loginStatus} = this.props;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    });
    try {
      params['state'] = JSON.parse(atob(params['state']));
      if (params['state']['action'] === 'bind') {
        // 绑定操作
        return;
      }
    } catch (e) {
      // ignore
    }

    // 登录页面清除缓存中的图谱相关数据
    for(let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.includes('MT_')) {
        localStorage.removeItem(localStorage.key(i));
      }
    }

    if (loginStatus === LoginStatusEnum.SUCCESS) {
      if (localStorage.getItem('token')) {
        this.props.history.push(this.state.redirectToPath);
      } else {
        if (params['login_type'] === 'pwd'||window.location.hostname==="ustcsv.joinmap.ai") {
          this.initLoginViaUsername();
        } else {
          this.gotoLoginViaWechatQRCode();
        }
      }
    } else if (this.props.authStep === AuthStepEnum.WAIT_FOR_WECHAT_SCAN_QR_CODE && params['login_type'] === 'pwd') {
      this.initLoginViaUsername();
    }
    if (params['p']) {
      requestAnimationFrame(() => this.setState({redirectToPath: params['p']}));
    }
    if (this.state.tryMagicLogin) {
      //this.props.magicLogin();
      //this.props.login('ustcsv_guest@joinmap.ai', '#gqTNyR&', 7);
      //setTimeout(function(){
      //  this.setState({loginFlag: true});
      //},300);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    console.log("nextProps", nextProps);
    let me = this;
    let params = qs.parse(nextProps.location.search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    });
    if (nextProps.loginStatus === LoginStatusEnum.SUCCESS) {
      if (localStorage.getItem('token')) {
        this.props.history.push(this.state.redirectToPath);
      } else {
        if (params['login_type'] === 'pwd'||window.location.hostname==="ustcsv.joinmap.ai") {
          this.initLoginViaUsername();
        } else {
          this.gotoLoginViaWechatQRCode();
        }
      }
    } else if (nextProps.authStep === AuthStepEnum.RESET_PWD_EMAIL_SENT) {
      if (this.props.authStep !== AuthStepEnum.RESET_PWD_EMAIL_SENT) {
        requestAnimationFrame(() => {
          me.setState({redirectToLoginAt: (new Date()).getTime() + 5000});
        });
      } else if (nextState.redirectToLoginAt < (new Date()).getTime()) {
        this.initLoginViaUsername();
      }
    }
    return true;
  }

  render() {
    let form = undefined, me = this;
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    });
    try {
      params['state'] = JSON.parse(atob(params['state']));
      if (params['state']['action'] === 'bind') {
        // 绑定操作
        return <div style={{position: 'absolute', height: '100vh', width: '100vw', top: 0, left: 0, backgroundColor: 'white'}}>&nbsp;</div>
      }
    } catch (e) {
      // ignore
    }
    switch (this.props.authStep) {
      case AuthStepEnum.WAIT_FOR_CHECK_USERNAME:
        form = (
          <CheckUsernameForm
            onCheckUsername={this.onCheckUsername}
            gotoRegister={this.gotoRegister}
            loginViaWechat={this.gotoLoginViaWechatQRCode}
            onInputFocus={this.onInputFocus}
            onInputBlur={this.onInputBlur}
            currentUsername={this.props.existedUsername}
            checkingUsername={this.props.normalLoginStatus === NormalLoginStatusEnum.CHECKING_USERNAME}
            guestLogin={this.guestLogin}
            userAgreement={this.userAgreement}
            privacyPolicy={this.privacyPolicy}
          />
        );
        break;
      case AuthStepEnum.WAIT_FOR_CHECK_PASSWORD:
        form = (
          <CheckPasswordForm
            onCheckPassword={this.checkPassword}
            onResetPwd={this.props.resetPwdConfirm}
            gotoCheckUsername={this.props.backToCheckUsername}
            loginViaWechat={this.gotoLoginViaWechatQRCode}
            onInputFocus={this.onInputFocus}
            onInputBlur={this.onInputBlur}
            currentUsername={this.props.existedUsername}
            checkingPassword={
              this.props.normalLoginStatus === NormalLoginStatusEnum.CHECKING_PASSWORD
            }
            guestLogin={this.guestLogin}
            userAgreement={this.userAgreement}
            privacyPolicy={this.privacyPolicy}
          />
        );
        break;
      case AuthStepEnum.WAIT_FOR_WECHAT_SCAN_QR_CODE:
        form = (/* 20240430
          <CheckUsernameForm
            onCheckUsername={this.onCheckUsername}
            gotoRegister={this.gotoRegister}
            loginViaWechat={this.gotoLoginViaWechatQRCode}
            onInputFocus={this.onInputFocus}
            onInputBlur={this.onInputBlur}
            currentUsername={this.props.existedUsername}
            checkingUsername={this.props.normalLoginStatus === NormalLoginStatusEnum.CHECKING_USERNAME}
          />*/
          <LoginViaWechatPanel
            loginStatus={this.props.weChatLoginStatus}
            weChatState={this.props.weChatLoginState}
            weChatFwhTicket={this.props.weChatFwhTicket}
            reset={this.gotoLoginViaWechatQRCode}
            goBack={
              this.props.goBackFromLoginViaWechatFn ? this.props.goBackFromLoginViaWechatFn :
                this.props.initLoginViaUsername
            }
            onWeChatResourceLoaded={this.props.onWeChatResourceLoaded}
            doLoginViaWeChat={this.props.doLoginViaWeChat}
            doGetWeChatFwhTicket={this.props.doGetWeChatFwhTicket}
            doCheckWeChatFwhTicket={this.props.doCheckWeChatFwhTicket}
            guestLogin={this.guestLogin}
            userAgreement={this.userAgreement}
            privacyPolicy={this.privacyPolicy}
          />
        );
        break;
      case AuthStepEnum.RESET_PWD_EMAIL_CONFIRM:
        form = (
          <ResetPwdConfirmPanel
            email={me.props.existedUsername}
            onSendResetPwdEmail={me.resetPwdViaEmail}
            backToCheckPassword={me.backToCheckPassword}
            sendingEmail={me.props.normalLoginStatus === NormalLoginStatusEnum.SENDING_RESET_PWD_EMAIL}
          />
        );
        break;
      case AuthStepEnum.RESET_PWD_EMAIL_SENT:
        form = (
          <EmailSentNoticePanel
            onBack={() => me.initLoginViaUsername()}
            email={me.props.existedUsername}
            redirectAt={me.state.redirectToLoginAt}
          />
        );
        break;
      default:
        form = (<div>&nbsp;</div>)
    }
    if (this.props.loginStatus === LoginStatusEnum.SUCCESS) {
      // 避免Particles报错问题
      return <div>&nbsp;</div>
    }
    return (
      <div className={`${style["container"]} dark-theme`}>
        <LoginRelatedView.Background />
        <LoginRelatedView.Header history={me.props.history} />
        <div className={style["content"]}>
          {form}
        </div>
        {/*<LoginRelatedView.Intro />*/}
        <LoginRelatedView.Bottom />
        <LoginRelatedView.Bottom />
        {me.state.showUserAgreementModal && <LoginRelatedView.UserAgreement 
          visible={me.state.showUserAgreementModal}
          onCancel={() => me.setState({showUserAgreementModal: false})}
        />}
        {me.state.showPrivacyPolicyModal && <LoginRelatedView.PrivacyPolicy 
          visible={me.state.showPrivacyPolicyModal}
          onCancel={() => me.setState({showPrivacyPolicyModal: false})}
        />}
      </div>
    )
  }
}

export default connect(
  state => ({
    loginStatus: state.account.loginStatus,
    normalLoginStatus: state.account.normalLoginStatus,
    weChatLoginStatus: state.account.weChatLoginStatus,
    weChatLoginState: state.account.weChatLoginState,
    weChatFwhTicket: state.account.weChatFwhTicket,
    authStep: state.account.authStep,
    existedUsername: state.account.existedUsername,
    goBackFromLoginViaWechatFn: state.account.goBackFromLoginViaWechatFn,
  }),
  dispatch => ({
    login: (username, password, remember) => dispatch(doLoginAction(username, password, remember)),
    magicLogin: () => dispatch(doMagicLoginAction()),
    checkUsername: (username) => dispatch(doCheckUsernameAction(username)),
    resetPwdConfirm: () => dispatch(resetPwdConfirmAction()),
    resetPwdViaEmail: (email) => dispatch(resetPwdViaEmailAction(email)),
    gotoCheckPassword: (username) => dispatch(userFoundAction(username)),
    backToCheckUsername: () => dispatch(backToCheckUsernameAction()),
    initLoginViaUsername: () => dispatch(initLoginViaUsernameAction()),
    initWechatQRCode: (backFn) => dispatch(initLoginViaWechatAction(() => {backFn(dispatch)})),
    initRegister: () => dispatch(initRegisterAction()),
    onWeChatResourceLoaded: (state) => dispatch(onWeChatResourceLoadedAction(state)),
    doLoginViaWeChat: (code) => dispatch(doLoginViaWeChatAction(code)),
    doGetWeChatFwhTicket: () => dispatch(doGetWeChatFwhTicketAction()),
    doCheckWeChatFwhTicket: (ticket) => dispatch(doCheckWeChatFwhTicketAction(ticket)),
  })
)(LoginView)
